import React from "react";
import carImg from "../../images/servicesassets/training/carTraining.png";

import { useLanguage } from "../../../src/languageContext";

function TrainingDescription() {
  const currentLanguage = useLanguage();

  return (
    <div className="training-body-container bg-light-blue ">
      <div className="training-image-block">
        <div className="training-image-heading">
          <h2
            className={`why-why heading text-word-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Why{" "}
          </h2>
          <h2
            className={`why-goken heading bold text-word-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Goken?
          </h2>
        </div>
        <div className="training-image">
          <img
            className="car-training-image"
            src={carImg}
            alt="Goken's Training Courses"
          ></img>
        </div>
      </div>
      <div className="training-content-block-container">
        <div className="training-content-block">
          <h3
            className={`training-heading semi-title bold border-left-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Engineering Experience
          </h3>
          <p className="para">
            For more than a decade, Goken has been a part of the Automotive
            Industry. We have trained hundreds of engineers to be successful and
            our training program bridges the divide between academic knowledge
            and practical application.
          </p>
        </div>
        <div className="training-content-block">
          <h3
            className={`training-heading semi-title bold border-left-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Product Design Focused
          </h3>
          <p className="para">
            We teach key concepts of product design in our training. For
            example- How to countermeasure sink marks on a plastic part, how to
            create parametric doghouses, how to create a reinforcement bracket
            that meets strength and weight criteria.
          </p>
        </div>
        <div className="training-content-block">
          <h3
            className={`training-heading semi-title bold border-left-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Customizable
          </h3>
          <p className="para">
            We customize our training curriculum and material to match your
            needs. From our training plan to the schedule, everything is shaped
            exactly to your desired outcome along with the trainee's background.
          </p>
        </div>
        <div className="training-content-block">
          <h3
            className={`training-heading semi-title bold border-left-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            Skills Oriented
          </h3>
          <p className="para">
            At the end of training we provide you an assessment of the trainee,
            their strengths, areas of struggle and a skill matrix that shows how
            the trainee performed on the various topics we trained on.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TrainingDescription;
