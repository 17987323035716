import React, { useState } from "react";
import RegisterTrainingCard from "../../components/atoms/training/RegisterTrainingCard";
import TrainingSlot from "./TrainingSlot";
import trainingTimelineProgram from "../../images/servicesassets/training/training-timeline-program.png";

function RegisterForTraining({
  confirmSubmit,
  setConfirmSubmit,
  formRegisterUser,
  trainingForm,
  bookTrainingx,
  trainingData,
  headingTxt,
  toggleTrainingForm,
  trainingSlotx,
  selectedMonth,
  onSubmit,
  trainingModal,
  toggleTraining,
  setPleaseWaitLoaderFalse,
  responseMessage,
  selectSlotDetailsData,
  setTrainingForm,
  setPleaseWaitLoaderTrue,
  dateSlot,
  pleaseWaitLoader,
  registerUser,
  handleClick,
  monthArr,
}) {
  return (
    <div className="section ">
      <div className="register-wrapper">
        {/* <div className="timeline-img-container" >
      <img className="timeline-img-program" src={ trainingTimelineProgram} ></img>
      </div> */}

        <div
          className="register-for-training-container m-tb-10"
          id="register-training-program"
          onClick={trainingModal}
        >
          {trainingData.map((data) => {
            return (
              <RegisterTrainingCard
                heading={data.name}
                level={data.level}
                detail={data.detail}
                hrs={data.hrs}
                levelIcon={data.levelIcon}
              />
            );
          })}
        </div>
        <TrainingSlot
          headingTxt={headingTxt}
          confirmSubmit={confirmSubmit}
          setConfirmSubmit={setConfirmSubmit}
          trainingForm={trainingForm}
          toggleTrainingForm={toggleTrainingForm}
          setTrainingForm={setTrainingForm}
          trainingData={trainingData}
          trainingSlotx={trainingSlotx}
          bookTrainingx={bookTrainingx}
          handleClick={handleClick}
          formRegisterUser={formRegisterUser}
          dateSlot={dateSlot}
          registerUser={registerUser}
          pleaseWaitLoader={pleaseWaitLoader}
          setPleaseWaitLoaderTrue={setPleaseWaitLoaderTrue}
          setPleaseWaitLoaderFalse={setPleaseWaitLoaderFalse}
          toggleTraining={toggleTraining}
          selectedMonth={selectedMonth}
          onSubmit={onSubmit}
          responseMessage={responseMessage}
          selectSlotDetailsData={selectSlotDetailsData}
          monthArr={monthArr}
        />
      </div>
    </div>
  );
}

export default RegisterForTraining;
