import React from "react";
import Fade from "react-reveal/Fade";
import { useLanguage } from "../../languageContext";
// Icons

import handOnTrainingB from "../../images/servicesassets/knowledge/Blue/HandontrainingBlue.svg";
import remoteTrainingB from "../../images/servicesassets/knowledge/Blue/remotetrainingBlue.svg";
import realWorldApplicationsB from "../../images/servicesassets/knowledge/Blue/RealWorldApplicationsBlue.svg";
import detailedAssesmentB from "../../images/servicesassets/knowledge/Blue/DetailedAssessmentBlue.svg";

import handOnTrainingG from "../../images/servicesassets/knowledge/Green/HandontrainingGreen.svg";
import remoteTrainingG from "../../images/servicesassets/knowledge/Green/remotetrainingGreen.svg";
import realWorldApplicationsG from "../../images/servicesassets/knowledge/Green/RealWorldApplicationsGreen.svg";
import detailedAssesmentG from "../../images/servicesassets/knowledge/Green/DetailedAssessmentGreen.svg";

import handOnTrainingR from "../../images/servicesassets/knowledge/Red/HandontrainingRed.svg";
import remoteTrainingR from "../../images/servicesassets/knowledge/Red/remotetrainingRed.svg";
import realWorldApplicationsR from "../../images/servicesassets/knowledge/Red/RealWorldApplicationsRed.svg";
import detailedAssesmentR from "../../images/servicesassets/knowledge/Red/DetailedAssessmentRed.svg";

// training keyfeatures new icons
import virtualTraining from "../../images/servicesassets/training/key-features-icon/classroom-virtual-training.svg";
import useOfRealWorld from "../../images/servicesassets/training/key-features-icon/use-of-real-world.svg";
import focusOnDesign from "../../images/servicesassets/training/key-features-icon/focus-on-design.svg";
import skillMatrix from "../../images/servicesassets/training/key-features-icon/skills-matrix.svg";
import dassault from "../../images/servicesassets/training/key-features-icon/dassault.svg";

//training program images

const KeyFeatures = () => {
  const currentLanguage = useLanguage();
  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="knowledge-training-solutions">
          <Fade bottom>
            <h2 className="heading knowledge-training-head">KEY FEATURES</h2>
          </Fade>
          <div className="wrapper-grid">
            <div className="element element-start ">
              <div className="element-head-container">
                <img
                  className={`key-icon-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                  src={virtualTraining}
                  alt="Knowledge Management Icon"
                ></img>
                <h3 className="heading semi-title m-top-10px">
                  Classroom/Virtual Training{" "}
                </h3>
              </div>

              <h4 className="para key-feature-para  ">
                Our trainings can be delivered through in-person classes or
                through virtual sessions. Attendees have the option to use their
                own or use Goken provided licenses.
              </h4>
            </div>

            <div className="element element-start ">
              <div className="element-head-container">
                <img
                  className={`key-icon-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                  src={useOfRealWorld}
                  alt="Knowledge Management Icon"
                ></img>
                <h3 className="heading semi-title m-top-10px">
                  Use of real world examples and training exercises
                </h3>
              </div>

              <h4 className="para key-feature-para ">
                Goken uses real world training excersises for plastic and
                sheetmetal parts, to ensure concepts learnt in training can be
                applied to their day to day design tasks.
              </h4>
            </div>

            <div className="element element-start ">
              <div className="element-head-container">
                <img
                  className={`key-icon-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                  src={focusOnDesign}
                  alt="Knowledge Management Icon"
                ></img>
                <h3 className="heading semi-title m-top-10px">
                  Focus on design for assembly and design for manufacturing
                </h3>
              </div>

              <h4 className="para key-feature-para ">
                Goken training is delivered by product development engineers and
                with a focus of working on designing parts while taking
                manufacturing and assembly into consideration.
              </h4>
            </div>

            <div className="element element-start ">
              <div className="element-head-container">
                <img
                  className={`key-icon-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                  src={skillMatrix}
                  alt="Knowledge Management Icon"
                ></img>
                <h3 className="heading semi-title m-top-10px">
                  Skills matrix and detailed assessment provided
                </h3>
              </div>

              <h4 className="para key-feature-para ">
                After training, Goken can provide a detailed skills assessment
                and feedback that highlight the associate's areas of strengths
                and weaknesses.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
