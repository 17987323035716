import React from "react";
import trainingProgram from "../../images/servicesassets/training/training-program-desktop.png";
import trainingProgramMobile from "../../images/servicesassets/training/training-program-mobile-updated.png";
import { useLanguage } from "../../../src/languageContext";

function TrainingProgram() {
  const currentLanguage = useLanguage();

  return (
    <div className="training-program-container">
      <img
        className="training-program-img"
        src={trainingProgram}
        alt="Goken's Training Courses"
      ></img>
      <img
        alt="Goken's Training Courses"
        className="training-program-img-mobile"
        src={trainingProgramMobile}
      ></img>

      <div className="training-program-content-block" id="redirectformcard">
        <h2
          className={` heading text-word-${
            currentLanguage ? currentLanguage : "en"
          }`}
        >
          Be A Part of Our Industry Leading Training Program
        </h2>
        <h3 className="training-program-text para" id="redirectform">
          Employees are your biggest expense and your most important asset.
          Upskilling your employees helps improve overall efficiency and output
          of your organization.{" "}
        </h3>
      </div>
    </div>
  );
}

export default TrainingProgram;
