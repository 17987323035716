import React, { useState } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import StaffingHeader from "../../molecules/staffing/staffingHeader";
import TrainingDescription from "../../molecules/training/trainingDescription";
import TrainingProgram from "../../molecules/training/TrainingProgram";

import RegisterForTraining from "../../molecules/training/RegisterForTraining";
import Insights from "../../molecules/Insights";
import bannerImg from "../../images/servicesassets/training/trainingHeader.png";
// import { useLanguage } from "../../languageContext"
import descImg from "../../images/servicesassets/know-management-description.png";

// card level icons
import introductoryIn from "../../images/servicesassets/training/level-icon/Introductory/introductory-blue.png";
import introductoryEn from "../../images/servicesassets/training/level-icon/Introductory/introductory-green.png";
import introductoryJa from "../../images/servicesassets/training/level-icon/Introductory/introductory-red.png";

import intermediateIn from "../../images/servicesassets/training/level-icon/Intermediate/intermediate-blue.png";
import intermediateEn from "../../images/servicesassets/training/level-icon/Intermediate/intermediate-green.png";
import intermediateJa from "../../images/servicesassets/training/level-icon/Intermediate/intermediate-red.png";
import advancedIn from "../../images/servicesassets/training/level-icon/Advanced/advanced-blue.png";
import advancedEn from "../../images/servicesassets/training/level-icon/Advanced/advanced-green.png";
import advancedJa from "../../images/servicesassets/training/level-icon/Advanced/advanced-red.png";

import { useStaticQuery, graphql } from "gatsby";
import KeyFeatures from "../../molecules/training/KeyFeatures";
import ProcessFlowTraining from "../../molecules/partnershipPage/ProcessFlowTraining";

import dassault from "../../images/servicesassets/training/key-features-icon/dassault.svg";
import { useLanguage } from "../../languageContext";
import SEO from "../../components/seo";
import StaffingDescription from "../../molecules/staffing/staffingDescription";
import HalfImageDescription from "../../molecules/Common/HalfImageDescription";

function Training() {
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [trainingForm, setTrainingForm] = useState(true);

  const [headingTxt, setHeadingTxt] = useState("");

  const [trainingSlotx, setTrainingSlotx] = useState(false);
  const [bookTrainingx, setBookTrainingx] = useState(false);

  const [dateSlot, setDateSlot] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [pleaseWaitLoader, setPleaseWaitLoader] = useState(false);

  const setPleaseWaitLoaderTrue = () => {
    setPleaseWaitLoader(true);
  };

  const setPleaseWaitLoaderFalse = () => {
    setPleaseWaitLoader(false);
  };
  const currentLanguage = useLanguage();

  const trainingData = [
    {
      name: "STANDARD 3D EXPERIENCE CATIA TRAINING ",
      level: "Introductory",
      levelIcon:
        currentLanguage === "en"
          ? introductoryEn
          : currentLanguage === "ja"
          ? introductoryJa
          : currentLanguage === "in"
          ? introductoryIn
          : introductoryEn,
      detail:
        "Understand 3D Experience interface, how to create parts and products and associated drawings. How to create surfaces and do operations on surfaces using GSD app. ",
      hrs: "40 hours",
      for_whom: "Design Engineers",
      software: "None",
      description: [
        " Understand 3DExperience interface, how to create parts and products and associated drawings. How to create surfaces and do operations on  surfaces using GSD app.",
      ],
      objectives: [
        {
          // name: "3DExperience Basics - Understand usage of Compass, Top Bar, Action Bar",
          sub_objectives: [
            "3DExperience Basics - Understand usage of Compass, Top Bar, Action Bar",
          ],
        },

        {
          // name: "Utilization of - Apps Common Services and settings, How to apply material,compute Mass.",
          sub_objectives: [
            "Utilization of - Apps Common Services and settings, How to apply material,compute Mass.",
          ],
        },

        {
          // name: "How to create and manage Publications",
          sub_objectives: ["How to create and manage Publications"],
        },

        {
          // name: "How to Reserve & unreserved parts, manage product maturity, apply revisions.",
          sub_objectives: [
            "How to Reserve & unreserved parts, manage product maturity, apply revisions.",
          ],
        },

        {
          // name: "Part Design - How to use Sketchers, create Sketch based features and dress up features; How to use Boolean Operations and create Surface based features ",
          sub_objectives: [
            "Part Design - How to use Sketchers, create Sketch based features and dress up features; How to use Boolean Operations and create Surface based features ",
          ],
        },

        {
          // name: "Assembly Design: How to create a Product, Add existing components, Add new components and creating constraints between parts; Managing links between parts; Managing Product Structure; Using the compass to move parts.",
          sub_objectives: [
            "Assembly Design: How to create a Product, Add existing components, Add new components and creating constraints between parts; Managing links between parts; Managing Product Structure; Using the compass to move parts.",
          ],
        },

        {
          // name: "Generative Shape Design: How to create Wireframe Elements, create Surfaces and use Surface Operation commands and replication commands, use of Draft Analysis, surface to solid features.",
          sub_objectives: [
            "Generative Shape Design: How to create Wireframe Elements, create Surfaces and use Surface Operation commands and replication commands, use of Draft Analysis, surface to solid features.",
          ],
        },

        {
          // name: "Drafting: Create and modify drawing views, add dimensions, add dress-up elements and add annotations and managing links between 3D data and drawing.",
          sub_objectives: [
            "Drafting: Create and modify drawing views, add dimensions, add dress-up elements and add annotations and managing links between 3D data and drawing.",
          ],
        },
      ],
      prerequisites: "None",
      excercise: [
        "Excercises for Part Design, Generative Surface design, Assembly design and Drafting",
      ],
    },

    {
      name: "ADVANCED Surface Design",
      level: "Advanced",
      levelIcon:
        currentLanguage === "en"
          ? advancedEn
          : currentLanguage === "ja"
          ? advancedJa
          : currentLanguage === "in"
          ? advancedIn
          : advancedEn,
      detail:
        "This course will convey how to use the Generative Shape Design Workbench to create and analyze 3D data with focus on Plastic part design ",
      hrs: "40 hours",
      for_whom: "Automotive Design Engineers",
      software: "Catia V5/ 3DExperience",
      description: [
        "This course will convey how to use the Generative Shape Design Workbench to create and analyze 3D data with focus on Plastic part design",
      ],
      objectives: [
        {
          // name: "Review Surface Design fundamentals",
          sub_objectives: ["Review Surface Design fundamentals"],
        },

        {
          // name: "",
          sub_objectives: [
            "Design a Plastic clip given an input die direction and reference shape,",
            "Utilize given layout and styling to create a part",
            "Surface Analysis Operations: Draft analysis, curvature analysis and connect checker",
            "Use of B-side features like doghouses, ribs, screw bosses etc.,",
            "Common Plastic part defects and how to fix them",
          ],
        },
      ],
      prerequisites: "CATIA Part Design, CATIA Generative Shape design ",
      excercise: [
        "Create a plastic clip for given inputs ",
        "Create B-side based on input A surface and attachment layout",
      ],
    },

    {
      name: "Transition to 3DExperience CATIA ",
      level: "Introductory",
      levelIcon:
        currentLanguage === "en"
          ? introductoryEn
          : currentLanguage === "ja"
          ? introductoryJa
          : currentLanguage === "in"
          ? introductoryIn
          : introductoryEn,
      detail:
        "Introduce the 3DEXPEREINCE Interface and familiarize users with overall structure and working of 3DEXPEREINCE and its key design applications.  ",
      hrs: "20 hours",
      for_whom: "Design Engineers",
      software: "None",
      description: [
        "Introduce the 3DEXPEREINCE Interface and familiarize users with overall structure and working of 3DEXPEREINCE and its key design applications. At the end of the webinar, attendees will be familiar with  3DEXPEREINCE structure and how to access applications, data, importance of roles, collaborative spaces, and how to save data. ",
      ],
      objectives: [
        {
          // name: "3DEXPERIENCE Basics -Understand usage of Compass, Top Bar, Action Bar Utilization of - Apps Common Services and Settings, How to apply material, compute Mass.",
          sub_objectives: [
            "3DEXPERIENCE Basics -Understand usage of Compass, Top Bar, Action Bar Utilization of - Apps Common Services and Settings, How to apply material, compute Mass.",
          ],
        },

        {
          // name: "How to create new content, save, export and import data from 3DX.",
          sub_objectives: [
            "How to create new content, save, export and import data from 3DX.",
          ],
        },

        {
          // name: "How to search for existing content, use 6WTags. Understand and edit preferences and settings, roles and access to roles.",
          sub_objectives: [
            "How to search for existing content, use 6WTags. Understand and edit preferences and settings, roles and access to roles.",
          ],
        },

        {
          // name: "Various data types in Catia and how to utilize them. Collaborative spaces and how to use them. ",
          sub_objectives: [
            "Various data types in Catia and how to utilize them. Collaborative spaces and how to use them. ",
          ],
        },
      ],
      prerequisites: "None",
      excercise: [
        "Live Demo on logging in, roles, search and accessing content. ",
      ],
    },

    {
      name: "Standard Catia V5 Training ",
      level: "Introductory",
      levelIcon:
        currentLanguage === "en"
          ? introductoryEn
          : currentLanguage === "ja"
          ? introductoryJa
          : currentLanguage === "in"
          ? introductoryIn
          : introductoryEn,
      detail:
        "Create 3D Parts and Assemblies. Create basic Sheetmetal Brackets given a Layout, create Plastic Parts with core and cavity understanding and basic Tooling know-how in CATIA V5. ",
      hrs: "80 hours",
      for_whom: "Design Engineers",
      software: "None",
      description: [
        "Create 3D Parts and Assemblies. Create basic Sheetmetal Brackets given a Layout, create Plastic Parts with core and cavity understanding and basic Tooling know-how in CATIA V5. ",
      ],
      objectives: [
        {
          // name: "Become comfortable with CATIA usage Pan, Zoom, and Rotate ",
          sub_objectives: [
            "Become comfortable with CATIA usage Pan, Zoom, and Rotate ",
          ],
        },

        {
          // name: "Configure your screen and toolbars ",
          sub_objectives: ["Configure your screen and toolbars "],
        },

        {
          // name: "",
          sub_objectives: [
            "Part Design -Sketcher: Sliding, Positioned Sketch, Creating Sketch Curves, Construction Elements, Constraining Sketches; Sketch based features; Dress up features ;Boolean Operations: Assemble, Add, Remove, Intersect, Union Trim Surface based features ",
            "Assembly Design: Create a Product, Add existing components, Add new components ; Create constraints between parts; Designing in Assembly Context; Move Commands, Explode Generate CAT Part from Product; Managing links between parts; Managing Product Structure; Using the compass to move parts   ",
            "Generative Shape Design: Designing Wireframe Elements: Points, Lines, Curves, Project, Intersect, Parallel, Circle, Surfaces: Extrude, Revolve, Offset, Sweep, Fill, Multi-Section, Blend; Surface Operations: Join, Split, Trim, Fillets, Chamfer, Transform, Extrapolate Replication: Create Circular, Rectangular Pattern, Use of Draft Analysis, Surface to Solid Features  ",
            "Drafting: Create and modify drawing views, project views, section views, add dimensions, add and update title block information; add dress-up elements- Hatching, Centerlines, Axis; Annotations - Text, Weld Symbol, Roughness Symbol; Rerouting Dimensions; Managing links between 3D data and drawing ",
          ],
        },
      ],
      prerequisites: "None",
      excercise: [
        "Exercises for Part Design, Generative Surface design, Assembly design and Drafting ",
      ],
    },
  ];

  const monthArr = [
    { name: "January", disabled: true },
    { name: "February", disabled: true },
    { name: "March", disabled: true },
    { name: "April", disabled: true },
    { name: "May", disabled: true },
    { name: "June", disabled: true },
    { name: "July", disabled: true },
    { name: "August", disabled: false },
    { name: "September", disabled: false },
    { name: "October", disabled: false },
    { name: "November", disabled: false },
    { name: "December", disabled: true },
  ];

  const selectSlotDetailsData = [
    {
      month: "January",
      dateText: ["23 Jan - 26 Jan"],
    },

    {
      month: "February",
      dateText: ["23 Feb - 26 Feb", "23 Feb - 26 Feb", "23 Feb - 26 Feb"],
    },

    {
      month: "March",
      dateText: ["23 Mar - 26 Mar"],
    },

    {
      month: "April",
      dateText: ["23 Apr - 26 Apr", "23 Apr - 26 Apr", "23 Apr - 26 Apr"],
    },
  ];

  const trainingModal = (event) => {
    let clickedElement = event.target;
    console.log(clickedElement, "event");
    if (clickedElement.classList.contains("training-card-register")) {
      let headElement = clickedElement.closest(
        ".training-card-register-container"
      );
      console.log(headElement, "head element");
      let parentHeadElement = headElement.closest(
        ".register-training-card-container"
      );
      let titleCard = parentHeadElement.querySelector(".training-card-head");
      let titleCardName = titleCard.innerHTML;
      console.log(titleCardName);
      setHeadingTxt(titleCardName);
      setTrainingSlotx(true);
      // console.log(parentHeadElement, 'btnn');

      const showTrainingSlot = document.querySelector(
        ".training-slot-container"
      );
      showTrainingSlot.style.display = "flex";

      const hideRegisterTraining = document.querySelector(
        ".register-for-training-container"
      );
      hideRegisterTraining.style.display = "none";

      setConfirmSubmit(false);
      setTrainingForm(false);
      setBookTrainingx(true);
    }
  };

  const handleClick = (event) => {
    let dataBtn = document.querySelectorAll(".training-btn-active");
    dataBtn.forEach((btn) => {
      if (btn.classList.contains("training-btn-active")) {
        btn.classList.remove("training-btn-active");
      }
    });

    let clickedElement = event.target;
    clickedElement.classList.add("training-btn-active");
    setDateSlot(clickedElement.innerHTML);
  };

  const registerUser = () => {
    const showTrainingForm = document.querySelector(".training-form-container");
    const hideTrainingSlotDetails = document.querySelector(".training-slot");

    const hideDetailBox = document.querySelector(".training-slot-col-two");

    const removeBorder = document.querySelector(".training-slot-container");
    removeBorder.style.border = "none";

    const showTrainingSlotDetails = document.querySelector(".training-slot");
  };

  const formRegisterUser = () => {
    const hideTrainingSlotDetails = document.querySelector(".training-slot");

    setTrainingSlotx(false);
    setBookTrainingx(false);

    const hideDetailBox = document.querySelector(".training-slot-col-two");
    // hideDetailBox.style.display = "none";

    const removeBorder = document.querySelector(".training-slot-container");
    removeBorder.style.border = "none";

    setTrainingForm(true);
  };

  const toggleTraining = () => {
    const showTrainingSlot = document.querySelector(".training-slot-container");
    showTrainingSlot.style.display = "none";

    const hideRegisterTraining = document.querySelector(
      ".register-for-training-container"
    );
    hideRegisterTraining.style.display = "grid";

    const showBookTraining = document.querySelector(".book-training");
    showBookTraining.style.display = "flex";

    setTrainingForm(false);

    setPleaseWaitLoader(false);
    setPleaseWaitLoaderFalse();
  };

  const toggleTrainingForm = () => {
    setTrainingForm(false);
    setTrainingSlotx(true);
    setBookTrainingx(true);
  };

  console.log(dateSlot);
  console.log(dateSlot.length);
  const removedChar = dateSlot.length - 3;
  console.log(dateSlot.substring(removedChar));
  const selectedMonth = dateSlot.substring(removedChar);

  let domain = "https://contactus.goken-global.com";
  let api = "/webapp/training";

  const onSubmit = (e) => {
    try {
      e.preventDefault();
      setPleaseWaitLoaderTrue();
      let firstName = e.target.first_name.value;
      console.log(firstName, "first name");
      let lastName = e.target.last_name.value;
      console.log(lastName, "last name");
      let emailAdd = e.target.email_add.value;
      console.log(emailAdd, "email add");
      let textArea = e.target.text_area.value;
      console.log(textArea, "text area");
      let telNo = e.target.tel_No.value;
      // console.log(telNo, "telephone");
      let orgName = e.target.org_name.value;
      console.log(orgName);
      let groupSize = e.target.groupSize.value;
      console.log(groupSize);
      let nationCode = e.target.nation_code.value;
      // console.log(nationCode);
      let phNo = nationCode + " " + telNo;
      console.log(phNo);

      let selectedMonths = e.target.selected_month.value;
      console.log(selectedMonths, "selected months");

      // let monthOptions = e.target.month_option.value;
      // console.log(monthOptions, 'month option');

      const params = new URLSearchParams();

      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };
      params.append("firstName", firstName + "-" + lastName);
      params.append("lastName", orgName);
      params.append("date_slot", dateSlot);
      params.append("month", selectedMonths);
      params.append("email", emailAdd);
      params.append("phone", phNo);
      params.append("remarks", textArea);
      params.append("course", headingTxt);
      params.append("group_size", groupSize);

      axios
        .post(domain + api, params, config)
        .then((response) => {
          console.log(response.data.message, "response");
          setResponseMessage(response.data.message);
          setTrainingForm(false);
          setConfirmSubmit(true);
        })
        .catch((Error) => {
          console.log(Error);
          console.log("Something Went Wrong. Please try again after sometime.");
        });
    } catch (e) {
      console.log(e);
    }
  };

  const ind = trainingData.findIndex((data) => data.name === headingTxt);
  console.log(trainingData[ind]);

  const data = useStaticQuery(graphql`
    query PartnershipPageData1 {
      gcms {
        partnerships {
          insightPages {
            ... on GraphCMS_InsightPage {
              shortDescription
              title
              slug
              image {
                url
              }
            }
          }
        }
        servicePages(where: { pageType: KNOWLEDGE_SOLUTION }) {
          description
        }
      }
    }
  `);
  try {
    return (
      <Layout isNavWhite={true} hideMap hideFAQ>
        <SEO
          canonicalLink={"https://www.goken-global.com/services/training/"}
          title="Training Services: Professional Training by Goken"
          description={`Transform your workforce with Goken's expert training services. Customized programs for skill enhancement and growth. Click to learn more!`}
          keywords={
            "product design training, manufacturing engineering training, CATIA V5, 3DEXPERIENCE CATIA, Enovia, Delmia, results driven, upskilling talent, industry leading training program"
          }
        />
        <StaffingHeader
          heading={" TRAINING for Product Design and Manufacturing"}
          btnTxt="Register"
          url="/contactus/"
          paragraph={`Our decades of experience in Product design and manufacturing has shown that effective training on CAD and PLM applications is a critical factor to success. Goken America offers training classes to product design and manufacturing engineers primarily focused on Catia V5, 3DEXperience Catia, Enovia and Delmia applications. ​`}
          paragraphTwo={`Goken training classes  are skills-focused, results driven and customizable, with an intent to eliminate months of on-the-job training. Whether onboarding new talent, upskilling existing associates, or setting up an associate development program, our method is the quickest path to a productive and effective workforce. ​`}
          image={bannerImg}
          dassaultImg={dassault}
          dassaultTxt={"Dassault Certified training partner"}
        />
        {/* <HalfImageDescription
          heading="Consulting Offering"
          paragraph={data.gcms.servicePages[0].description}
          image={descImg}
        /> */}
        {/* <StaffingDescription
          headingOne="Consulting Offering"
          description={data.gcms.servicePages[0].description}
          image={descImg}
        /> */}
        <KeyFeatures />
        <TrainingDescription />
        {/* <PartnershipTrainingSolution /> */}
        <ProcessFlowTraining />
        <TrainingProgram />

        {/* <RegisterForTraining
          trainingSlotx={trainingSlotx}
          confirmSubmit={confirmSubmit}
          formRegisterUser={formRegisterUser}
          setConfirmSubmit={setConfirmSubmit}
          bookTrainingx={bookTrainingx}
          trainingForm={trainingForm}
          toggleTrainingForm={toggleTrainingForm}
          setTrainingForm={setTrainingForm}
          trainingData={trainingData}
          headingTxt={headingTxt}
          trainingModal={trainingModal}
          handleClick={handleClick}
          dateSlot={dateSlot}
          registerUser={registerUser}
          pleaseWaitLoader={pleaseWaitLoader}
          setPleaseWaitLoaderTrue={setPleaseWaitLoaderTrue}
          setPleaseWaitLoaderFalse={setPleaseWaitLoaderFalse}
          toggleTraining={toggleTraining}
          onSubmit={onSubmit}
          selectedMonth={selectedMonth}
          responseMessage={responseMessage}
          selectSlotDetailsData={selectSlotDetailsData}
          monthArr={monthArr}
        /> */}
        <div className="w-90-wrapper">
          <Insights
            insights={data.gcms.partnerships[0].insightPages}
            showTitle={true}
          />
        </div>
      </Layout>
    );
  } catch (error) {
    console.log(error);
  }
}

export default Training;
