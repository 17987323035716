import React from "react";
import { Fade } from "react-reveal";
import processFlow1 from "../../images/servicesassets/training/processFlow/Process-flow-08.png";
import processFlow2 from "../../images/servicesassets/training/processFlow/Process-flow-09.png";
import processFlow3 from "../../images/servicesassets/training/processFlow/Process-flow-10.png";
import processFlow4 from "../../images/servicesassets/training/processFlow/Process-flow-11.png";
import processFlow5 from "../../images/servicesassets/training/processFlow/Process-flow-12.png";

//Mobile
import processFlow1Mobile from "../../images/servicesassets/training/processFlow/process-flow-mobile-06.png";
import processFlow2Mobile from "../../images/servicesassets/training/processFlow/process-flow-mobile-07.png";
import processFlow3Mobile from "../../images/servicesassets/training/processFlow/process-flow-mobile-08.png";
import processFlow4Mobile from "../../images/servicesassets/training/processFlow/process-flow-mobile-09.png";
import processFlow5Mobile from "../../images/servicesassets/training/processFlow/process-flow-mobile-10.png";

function ProcessFlowTraining() {
  return (
    <div className="p-top-3em m-bottom-3em ">
      <div className="heading processflow-head ">
        <h2>Customized Training Solutions</h2>
      </div>
      <div className="process-flow-training w-90-wrapper ">
        <Fade left duration={1000} delay={0}>
          <div className="process-flow-training-image-container">
            <img
              className="process-flow-training-image"
              src={processFlow1}
              alt="Goken's Training Process"
            ></img>
            <img
              className="process-flow-training-image-mobile"
              src={processFlow1Mobile}
              alt="Goken's Training Process"
            ></img>
          </div>
        </Fade>

        <Fade left duration={1000} delay={500}>
          <div className="process-flow-training-image-container">
            <img
              className="process-flow-training-image"
              src={processFlow2}
              alt="Goken's Training Process"
            ></img>
            <img
              className="process-flow-training-image-mobile"
              src={processFlow2Mobile}
              alt="Goken's Training Process"
            ></img>
          </div>
        </Fade>

        <Fade left duration={1000} delay={1000}>
          <div className="process-flow-training-image-container">
            <img
              className="process-flow-training-image"
              src={processFlow3}
              alt="Goken's Training Process"
            ></img>
            <img
              className="process-flow-training-image-mobile"
              src={processFlow3Mobile}
              alt="Goken's Training Process"
            ></img>
          </div>
        </Fade>

        <Fade left duration={1000} delay={1500}>
          <div className="process-flow-training-image-container">
            <img
              className="process-flow-training-image"
              src={processFlow4}
              alt="Goken's Training Process"
            ></img>
            <img
              className="process-flow-training-image-mobile"
              src={processFlow4Mobile}
              alt="Goken's Training Process"
            ></img>
          </div>
        </Fade>

        <Fade left duration={1000} delay={2000}>
          <div className="process-flow-training-image-container">
            <img
              className="process-flow-training-image"
              src={processFlow5}
              alt="Goken's Training Process"
            ></img>
            <img
              className="process-flow-training-image-mobile"
              src={processFlow5Mobile}
              alt="Goken's Training Process"
            ></img>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default ProcessFlowTraining;
